import React, { useState, useEffect, useRef } from 'react';
 
interface Props {
  images: Array<string>;
}

const ImageSlider: React.FC<Props> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const currentIndexRef = useRef(currentIndex)

    useEffect(() => {
        currentIndexRef.current = currentIndex
    })

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((currentIndexRef.current + 1) % images.length);
        }, 200);
        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, []);

    return (
        <img className='h-[100%]' src={images[currentIndex]} alt="" />
    );
};

 
export default ImageSlider;