import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const store = createSlice({
  name: "channel",
  initialState: {
    channelList: []
  },
  reducers: {
    updateChannels(state, action) {
      state.channelList = action.payload
    },
  }
})

const fetchChannelList = () => {
  return async (dispatch: any) => {
    const res = await axios.get('http://geek.itheima.net/v1_0/channels')
    dispatch(updateChannels(res.data.data.channels))
  }
}

const { updateChannels } = store.actions

const reducer = store.reducer

export { updateChannels, fetchChannelList }
export default reducer