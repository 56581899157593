import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Box } from '@mui/material';
import AudioPlayer from 'src/components/AudioPlayer'
import ImageSlider from 'src/components/ImageSlider';

var imgList: any = [
    '/images/dance_0001.png',
    '/images/dance_0002.png',
    '/images/dance_0003.png',
    '/images/dance_0004.png',
    '/images/dance_0005.png',
    '/images/dance_0006.png',
    '/images/dance_0007.png',
    '/images/dance_0008.png',
    '/images/dance_0009.png',
    '/images/dance_0010.png',
    '/images/dance_0011.png',
]
        
function Home() {
    return (
      <div className='home-bg5 w-[100%] h-[100%] absolute overflow-hidden'>
            <div className='w-[100%] h-[100%] pt-[40px] pb-[20px] overflow-y-auto'>
                <div className='w-[auto] h-[100%] mx-[auto] relative'>
                    <div className='flex items-center justify-center mb-[10px]'>
                        <img src="/images/top_logo.png" alt='' className='w-[61px] h-[60px]' />
                        <img src="/images/top_lucky_miuuu.png" alt='' className='w-[195px] h-[57px]' />
                    </div>
                    <div className='w=[100%] h-[auto] md:w-[auto] md:h-[calc(100%_-_70px)] flex items-center justify-center'>
                        <ImageSlider images={imgList} />
                    </div>

                    <AudioPlayer />

                    <div
                        className='
                            relative
                            w-[calc(100%_-_30px)]
                            mx-[auto] 
                            mt-[20px]
                            md:w-[332px]
                            h-[115px]
                            md:absolute
                            md:left-[calc(50%_-_166px)]
                            md:bottom-0
                            md:mt-0
                            '
                    >
                        <img src="/images/btn_top.png" alt='' className='h-[65px] mx-[auto]' />
                        <div
                            className='
                                w-[100%]
                                h-[50px]
                                flex
                                items-center
                                justify-center
                                rounded-[10px]
                                bg-black
                                text-white
                                text-[16px]
                                font-[600]
                                mt-[-3px]
                                cursor-pointer
                            '
                            onClick={() => window.location.href = 'https://t.me/MiuuuCommunity'}
                        >
                            Join Telegram
                        </div>
                        <div className='w-[100%] h-[20px] md:h-0'></div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Home;
