import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, AppState } from '../index'

import { updateDarkMode } from './userStore'

export function useIsDarkMode() {
  const { darkMode } = useSelector((state: AppState) => state.userReducer)

  return darkMode ?? false
}

export function useChangeDarkMode(): (darkMode: boolean) => void {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (darkMode: boolean) => {
      dispatch(updateDarkMode({ darkMode }))
    },
    [dispatch]
  )
}
