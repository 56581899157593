import React from 'react';
import { Box } from '@mui/material';
import { routes } from '../constants/routes'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Home from './home/Home'
import About from './about/About'

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={routes.home} element={<Home />} />
                <Route path={routes.about} element={<About />} />
                <Route path="*" element={<Navigate to={routes.home} replace />} />
            </Routes>
        </BrowserRouter>
    
  );
}

export default App;
