import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import userReducer from './user/userStore'
import counterReducer from './counter/counterStore'
import channelReducer from './channel/channelStore'

const allReducer = combineReducers({
  userReducer,
  counterReducer,
  channelReducer
})

const persistConfig = {
  key: 'root',//本地存储的key
  storage,
  whitelist: ['userReducer'], //白名单，blacklist: 黑名单
}

const persistedReducer = persistReducer(persistConfig, allReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
})
const persistor = persistStore(store)

export { store, persistor }

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch