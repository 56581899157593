import { createSlice } from "@reduxjs/toolkit";

const store = createSlice({
  name: "counter",
  initialState: {
    count: 0
  },
  reducers: {
    increment(state) {
      state.count++
    },
    decrement(state) {
      state.count--
    }
  }
})

const { increment, decrement } = store.actions

const reducer = store.reducer

export { increment, decrement }
export default reducer