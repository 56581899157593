import { createSlice } from "@reduxjs/toolkit";

const store = createSlice({
  name: "user",
  initialState: {
    darkMode: false
  },
  reducers: {
    updateDarkMode(state, action) {
      state.darkMode = action.payload.darkMode
    },
  }
})

const { updateDarkMode } = store.actions

const reducer = store.reducer

export { updateDarkMode }
export default reducer