import React from 'react';
import { Box } from '@mui/material';

function About() {
  return (
    <div className='w-[100px] h-[100px] bg-black'>About</div>
  );
}

export default About;
